import React from "react";
import { NavBar } from "../../components";
import { PrimaryButton } from "../Button";
import { Eval404 } from "../Assets";
import { Row, Col } from "antd";
import "./style.scss";
import { getCurrentUser, isAdmin, isArchitect } from "../../store";
import { useSelector } from "react-redux";

const ConsoleEndPoint = `https://console.${process.env.REACT_APP_MAIN_DOMAIN}/login`;
export const PageNotFound = (props) => {
  const user = useSelector(getCurrentUser);
  const MODULE_ID = "arc";
  const handleRelogin = () => {
    window.location.assign(ConsoleEndPoint);
  };
  return (
    <div className="col w-100 h-100 main-screen white o-hide">
      {/* Render NavBar */}
      <NavBar
        currentModule={MODULE_ID}
        user={user}
        isAdmin={isAdmin(user)}
        isArchitect={isArchitect(user)}
      />
      <div className="full w-100 h-100 col page-not-found">
        <Row container className="h-100 w-100">
          <Col item xs={12} sm={12} md={12} lg={12}>
            <div className="col oval-container text-container">
              <div className="exo2 txt clang">Oops! This is a dead-end...</div>
              <div className="exo2 txt-info">
                <p className="exo2">
                  This page has been encrypted for the upcoming certification
                  audit...
                  <br />
                  Or maybe it just can't be found!
                </p>
                <p className="exo2">
                  In the meantime, keep your defenses strong &{" "}
                  <span onClick={props.onHome} className="c1155CC">
                    go back
                  </span>{" "}
                  to safety!
                </p>
                <p className="exo2 last-line">
                  Here are some helpful options instead...
                </p>
              </div>
              <ul className="options-list">
                <li className="mb-1">
                  <PrimaryButton
                    color="#1155CC"
                    className="exo2 back-home"
                    variant="lite"
                    label="Go Home"
                    onClick={props.onHome}
                  />
                </li>
                <li className="mb-1">
                  <PrimaryButton
                    color="#1155CC"
                    className="exo2 back-home"
                    variant="lite"
                    label="Re-login"
                    onClick={handleRelogin}
                  />
                </li>
                <li className="">
                  <PrimaryButton
                    color="#1155CC"
                    className="exo2 back-home"
                    variant="lite"
                    label="Get Help"
                  />
                </li>
              </ul>
            </div>
          </Col>
          <Eval404 alt="Oval-Not-Found" className="oval-404" />
        </Row>
      </div>
    </div>
  );
};
